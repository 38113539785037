// @ts-strict-ignore
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { useTranslation } from 'react-i18next';
import { IdentityPreviewV1, sqUsersApi } from '@/sdk';
import { Icon } from '@/core/Icon.atom';
import { isAdmin } from '@/services/authorization.service';

export type IdentityOption = Pick<IdentityPreviewV1, 'id' | 'name' | 'username' | 'email'> & {
  customOption?: boolean;
  label?: string;
};

interface SelectIdentityIF {
  idForLabel?: string;
  setIdentity: (object: IdentityOption | IdentityOption[]) => void;
  onIdentityChange?: (identity: IdentityOption | IdentityOption[]) => void;
  identity: IdentityOption | IdentityOption[];
  allowGroups?: boolean;
  includeAllProperties?: boolean;
  startEditable?: boolean;
  prefillIdentity?: boolean;
  placeholder?: string;
  tooltip?: string;
  isInvalid?: boolean;
  autoFill?: boolean;
  unauthorizedTooltip?: string;
  multiple?: boolean;
  clearIdentityWhenEmpty?: boolean;
  allowNew?: boolean;
}

/** Input field for searching and selecting Seeq Identities (Users or Groups) */
export const SelectIdentity: React.FunctionComponent<SelectIdentityIF> = (props) => {
  const {
    setIdentity,
    onIdentityChange,
    identity,
    prefillIdentity = false,
    placeholder,
    tooltip,
    unauthorizedTooltip,
    idForLabel,
    allowGroups = false,
    includeAllProperties = false,
    startEditable = false,
    multiple = false,
    isInvalid,
    clearIdentityWhenEmpty = false,
    autoFill = true,
    allowNew = false,
  } = props;
  const { t } = useTranslation();

  const [loadingIdentities, setLoadingIdentities] = useState(false);
  const [options, setOptions] = useState<IdentityOption[]>([]);
  const [editingIdentity, setEditingIdentity] = useState(startEditable);
  const [typeahead, setTypeahead] = useState(null);
  const [showPreselectedIdentity, setShowPreselectedIdentity] = useState(true);

  useEffect(() => {
    setShowPreselectedIdentity(false);
  }, []);

  useEffect(() => {
    if (_.isEmpty(identity) && clearIdentityWhenEmpty && !loadingIdentities) {
      typeahead?.getInstance().clear();
    }
  }, [identity]);

  const renderMenu = (option, props) => (
    <div>
      {option.type === 'User' && <Icon icon="fa-user" extraClassNames="width-18" type="text" />}
      {option.type === 'UserGroup' && <Icon icon="fa-users" extraClassNames="width-18" type="text" />}
      <Highlighter search={props.text}>{option.name}</Highlighter>
      {option.type === 'User' && (
        <div>
          <small>{option.email || option.username}</small>
        </div>
      )}
    </div>
  );

  const handleIdentitiesSearch = (query) => {
    setLoadingIdentities(true);
    setIdentity(multiple ? [] : ({} as IdentityOption));
    const toArrayIfMultiple = (object) => (multiple ? [object] : object);

    return (
      sqUsersApi
        .autocompleteUsersAndGroups({ query })
        // Filter out user groups
        .then(({ data: { items } }) => {
          const usersAndGroups = allowGroups ? items : _.filter(items, { type: 'User' });
          const filtered: IdentityOption[] = includeAllProperties
            ? usersAndGroups
            : _.map(usersAndGroups, (userOrGroup) => _.pick(userOrGroup, ['id', 'name', 'username', 'type', 'email']));
          setOptions(filtered);
          if (filtered.length === 1 && autoFill) {
            setIdentity(toArrayIfMultiple(filtered[0]));
          }
          setLoadingIdentities(false);
        })
    );
  };

  const onInputChange = (selected) => {
    if (multiple) {
      setIdentity(selected);
      onIdentityChange?.(selected);
    } else {
      const identity = (selected.length === 1 ? _.first(selected) : {}) as IdentityOption;
      setIdentity(identity);
      onIdentityChange?.(identity);
    }
  };

  const editIdentity = () => {
    setEditingIdentity(true);
    setIdentity({} as IdentityOption);
  };

  const renderIdentity = (option): string => {
    if (_.isNil(option)) {
      return '';
    }

    if (option?.email) {
      return `${option.name} (${option.email})`;
    } else if (option?.username) {
      return `${option.name} (${option.username})`;
    } else {
      return option?.name;
    }
  };

  const renderEditable = (
    <div className="input-group width-maximum" data-testid={`selectIdentityInput_${idForLabel}`}>
      <AsyncTypeahead
        id={idForLabel}
        ref={setTypeahead}
        labelKey={(option) => renderIdentity(option)}
        allowNew={allowNew}
        multiple={multiple}
        options={options}
        isLoading={loadingIdentities}
        onSearch={handleIdentitiesSearch}
        onChange={onInputChange}
        renderMenuItemChildren={renderMenu}
        placeholder={t(placeholder)}
        selected={showPreselectedIdentity && prefillIdentity ? identity : undefined}
        isInvalid={isInvalid}
      />
    </div>
  );

  const renderDisplayMode = (
    <div className="input-group width-maximum" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <input id={idForLabel} className="form-control" type="text" disabled={true} value={renderIdentity(identity)} />
      <span
        className="sq-btn input-group-btn input-group-text cursorPointer fs15"
        data-testid={`selectIdentityEditIdentity_${idForLabel}`}
        onClick={editIdentity}>
        <Icon icon="fc-edit" type="text" tooltip={tooltip} />
      </span>
    </div>
  );

  const renderViewOnly = (
    <div className="input-group width-maximum" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <HoverTooltip text={unauthorizedTooltip} placement="top">
        <input id={idForLabel} className="form-control" type="text" disabled={true} value={renderIdentity(identity)} />
      </HoverTooltip>
    </div>
  );

  if (editingIdentity) {
    return renderEditable;
  } else if (isAdmin()) {
    return renderDisplayMode;
  } else {
    return renderViewOnly;
  }
};
