// @ts-strict-ignore
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { Icon, IconType } from '@/core/Icon.atom';
import { TableFilterPopover } from '@/core/tableUtilities/TableFilterPopover.organism';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { Placement } from 'react-bootstrap/Overlay';

interface FilterDropdownProps {
  columnKey: string;
  setColumnFilter?: (key: string, filter: TableColumnFilter) => void;
  iconType?: IconType;
  columnFilter?: TableColumnFilter;
  isFilterDisabled?: boolean;
  isStringColumn?: boolean;
  isDurationColumn?: boolean;
  distinctStringValues?: string[];
  helpText?: string;
  thresholds?: ThresholdOutputV1[];
  placement?: Placement;
  onIconClick?: () => void;
}

export const TableFilterIcon: React.FunctionComponent<FilterDropdownProps> = ({
  columnKey,
  setColumnFilter,
  iconType = 'theme',
  columnFilter,
  isFilterDisabled = false,
  isStringColumn = false,
  isDurationColumn = false,
  distinctStringValues,
  helpText,
  thresholds,
  placement = 'bottom',
  onIconClick = _.noop,
}) => {
  const isFiltered = !_.isUndefined(columnFilter);

  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);

  const filterIconRef = useRef<HTMLDivElement>(null);

  const renderFilterPopover = () => (
    <TableFilterPopover
      columnFilter={columnFilter}
      columnKey={columnKey}
      notifyOnClose={() => setShowColumnFilterPopover(false)}
      setColumnFilter={(key, filter) => setColumnFilter(key, filter)}
      isStringColumn={isStringColumn}
      isDurationColumn={isDurationColumn}
      distinctStringValues={distinctStringValues}
      thresholds={thresholds}
      helpText={helpText}
      placement={placement}
      target={filterIconRef.current}
      show={!isFilterDisabled && showColumnFilterPopover}
    />
  );

  const getFilterIconType = (hasFilter: boolean) => (!hasFilter ? 'gray' : iconType ?? 'theme');

  return (
    <>
      {!isFilterDisabled && isFiltered && (
        <div
          className="sq-icon-hover cursorPointer"
          data-testid="columnFilterIconWrapper"
          id={`filterDropdown-${columnKey}`}
          ref={filterIconRef}>
          <Icon
            icon="fa-filter"
            extraClassNames="fa-fw mt2"
            testId="columnFilterIcon"
            onClick={() => {
              onIconClick();
              setShowColumnFilterPopover(true);
            }}
            type={getFilterIconType(isFiltered)}
          />
        </div>
      )}
      {renderFilterPopover()}
      {isFilterDisabled && isFiltered && (
        <Icon
          icon="fa-filter"
          extraClassNames="fa-fw mt2 ml5"
          testId="columnFilterIcon"
          type={getFilterIconType(isFiltered)}
        />
      )}
    </>
  );
};
