import React from 'react';
import { Icon } from '@/core/Icon.atom';

interface TableLoadingIndicatorProps {
  testId?: string;
}

export const TableLoadingIndicator: React.FunctionComponent<TableLoadingIndicatorProps> = ({ testId }) => {
  return (
    <div data-testid={testId} className="flexColumnContainer flexCenter pt50 pb50">
      <Icon icon="fa-spinner" extraClassNames="fa-pulse fa-5x" />
    </div>
  );
};
